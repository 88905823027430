import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q4",
  context: [
    "Temos uma questão desafiadora! Nossos fornecedores de serviços de engenharia trabalham com Softwares CADs 3D diferentes.",
    "Entretanto, precisamos que todos os itens de cada projeto estejam no mesmo ambiente formando assim, um único produto.",
  ],
  content: ["Como atualmente você vem enfrentando essa situação?"],
  options: [
    {
      text: "Nossos fornecedores só enviam os projetos em PDF 2D",
      xValue: 0.25,
      yValue: 0,
      feedbackContent:
        "Sobre gerenciar os itens dos fornecedores, trabalhar com PDF's 2D compartilhados acabam restringindo a colaboração e visualização em tempo real do andamento do projeto.",
    },
    {
      text: "Atualmente eu exijo que meus fornecedores se adequem e trabalhem com o mesmo software que eu.",
      xValue: 0.75,
      yValue: 0.25,
      feedbackContent:
        "Segregar fornecedores que trabalhem apenas com o mesmo software pode não ser a opção mais viável, pois já existe atualmente outras  formas de compartilhamento de arquivos que utilizam o formato nativo.",
    },
    {
      text: "Para os fornecedores que não seguem o mesmo padrão, pedimos para que eles enviem seus arquivos em formatos 3D genéricos (STEP, IGES e etc...). Não é o ideal, mas vem funcionando!",
      xValue: 0.5,
      yValue: 0.25,
      feedbackContent:
        "Para lidar com fornecedores que não seguem o mesmo padrão, é indispensavel extrair o máximo de informações, mas arquivos STEP perdem características no compartilhamento de CAD 3D, como iluminação e configurações de câmeras.",
    },
    {
      text: "Em nosso processo, temos a capacidade de receber esses arquivos em seus formatos nativos e utilizá-los para criar virtualmente o produto final, sem perda de informações.",
      xValue: 1,
      yValue: 1,
      extension: {
        type: "OpenAnswer",
        title: "Qual plataforma CAD você usa para isso?",
      },
      feedbackContent:
        "A composição de um produto nem sempre vem de peças desenhadas em apenas um Software CAD, e trabalhar com uma ferramenta que te possibilita utilizar todos os tipos de extensão em sua forma nativa certamente abrirá um leque de fornecedores e otimiza o tempo do projeto, então consequentemente se torna a melhor opção.",
    },
  ],
  backgroundImage: "/img/cafeteria/bg-conselho.png",
  characterName: "Melissa - Gerente de Engenharia",
  sceneNumber: 5,
} as IQuestion;
