import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q9",
  context: [],
  content: [
    "Estou iniciando um projeto de redefinição de nossa infraestrutura e gostaria de saber se os softwares que vocês utilizam necessariamente precisam ser instalados em nossos servidores físicos ou se poderíamos considerar a utilização destas soluções em nuvem.",
  ],
  options: [
    {
      text: "As soluções que utilizamos não possuem suporte a cloud, com isso, creio que não posso te ajudar nesta iniciativa.",
      xValue: 0,
      yValue: 0,
      feedbackContent:
        "Soluções em nuvem vem se tornando essencial ultimamente, e a tendência é que o mercado adote cada vez mais ferramentas assim. Sem a necessidade de investimentos em hardware muito potentes e servidores locais, você ainda garante um ambiente totalmente disponível a todo momento e 100% seguro.",
    },
    {
      text: "Atualmente utilizamos nossos servidores locais para rodar nossos principais sistemas e armazenar os projetos e não gostaríamos de mudar isso.",
      xValue: 0.0,
      yValue: 0.0,
      feedbackContent:
        "Soluções em nuvem vem se tornando essencial ultimamente, e a tendência é que o mercado adote cada vez mais ferramentas assim. Sem a necessidade de investimentos em hardware muito potentes e servidores locais, você ainda garante um ambiente totalmente disponível a todo momento e 100% seguro.",
    },
    {
      text: "As soluções que utilizamos possuem suporte a cloud mas não tenho um plano definido para essa migração.",
      xValue: 0.25,
      yValue: 0.75,
      feedbackContent:
        "Realizar a migração para Nuvem o quanto antes será uma etapa totalmente importante. Essa mudança acarretará em muito mais colaboração do time e entre outras áreas, uma comunicação limpa e informações em tempo real disponível a todo momento.",
    },
    {
      text: "Nossas ferramentas estão aptas a rodar em cloud e nosso time está aberto para iniciar um processo de migração, por favor considere nosso departamento favorável ao seu projeto!",
      xValue: 0.25,
      yValue: 1,
      feedbackContent:
        "Empresas preparadas com ferramentas cloud saem na frente da concorrência, pois entregam algo totalmente novo, uma forma diferente, mas muito funcional e ágil de trabalhar.",
    },
  ],
  backgroundImage: "/img/cafeteria/bg-scene-gray-room.jpg",
  characterName: "Felipe - Diretor de TI",
  sceneNumber: 10,
} as IQuestion;
