import { getAnalytics } from "firebase/analytics";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
    addDoc,
    collection,
    CollectionReference,
    doc,
    FieldValue,
    getDoc,
    getDocs,
    getFirestore,
    query,
    QueryConstraint,
    updateDoc
} from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAeObrYVLGTtuvsFDUTkLMRIFqf_LtgflE",
  authDomain: "simulation-01.firebaseapp.com",
  projectId: "simulation-01",
  storageBucket: "simulation-01.appspot.com",
  messagingSenderId: "666475920455",
  appId: "1:666475920455:web:430f20bc90503d05dbc562",
  measurementId: "G-350FXGMWB0",
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const firestore = getFirestore(app);

export async function firebaseGet<T extends { id: string }>(
  path: string,
  ...pathSegments: string[]
): Promise<T | undefined> {
  const docRef = doc(firestore, path, ...pathSegments);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) {
    return undefined;
  } else {
    const doc: T = docSnap.data() as T;
    doc.id = docSnap.id;
    return doc;
  }
}

export async function firebaseQuery<T extends { id: string }>(
  name: string,
  ...constraint: QueryConstraint[]
): Promise<T[]> {
  const c: CollectionReference<T> = collection(
    firestore,
    name
  ) as CollectionReference<T>;
  const q = query<T>(c, ...constraint);
  const snap = await getDocs<T>(q);
  const results: T[] = [];
  snap.forEach((doc) => {
    const obj = doc.data();
    obj.id = doc.id;
    results.push(obj);
  });
  return results;
}

export async function firebaseInsert(
  col: string,
  doc: Record<string, unknown>
) {
  return addDoc(collection(firestore, col), doc);
}

export async function firebaseUpdate(
  col: string,
  data: { [x: string]: FieldValue | Partial<unknown> | undefined | null },
  ...pathSegments: string[]
) {
  const docRef = doc(firestore, col, ...pathSegments);
  for (const key in data) {
    const value = data[key];
    if (value === undefined) data[key] = null;
  }
  return await updateDoc(docRef, data);
}
