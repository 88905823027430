import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q8",
  context: [
    "Conseguimos fechar o contrato com um cliente dos EUA que traz um produto novo que demandará um novo",
    "layout da nossa linha de produção, fabricação de ferramentais, bem como treinamento da equipe de produção e montagem.",
  ],
  content: [
    "Teremos que ser ágeis para implementar essas mudanças em nossa fábrica. Como normalmente realizamos esses ajustes para introdução de novos processos produtivos?",
  ],
  options: [
    {
      text: "Mantemos o layout atual pois sempre fizemos dessa forma a fabricação de nossos produtos.",
      xValue: 0.25,
      yValue: 0,
      feedbackContent:
        "Para a implementação de novos pocessos produtivos é importante sempre ter uma certa flexibilidade e procurar manter um layout de fácil adaptação para caso haja uma oportunidade de negócio que não pode ser perdida. Manter o layout único e padrão talvez não seja funcional, pois haverá conflitos e principalmente precisará de um prazo maior para a entrega por um detalhe de planejamento.",
    },
    {
      text: "Não possuímos soluções para simulação destes cenários e normalmente realizamos as simulações e ajustes durante a produção real, em fábrica.",
      xValue: 0.25,
      yValue: 0.5,
      feedbackContent:
        "Para a implementação de novos processos produtivos, realizar ajustes durante a operação e produção pode ser extremamente arriscado, pois planejamento é essencial para mitigar riscos e estabelecer prazos. Contratar uma consultoria para cada mudança necessária pode ser muito custoso a longo prazo e já existem ferramentas capazes de realizar automaticamente a construção do melhor layout para tal atividade.",
    },
    {
      text: "Podemos contratar uma empresa de consultoria para nos apoiar nas simulações e definições do melhor cenário para produção deste novo produto, porém este custo não foi considerado na proposta.",
      xValue: 0.5,
      yValue: 0.5,
      feedbackContent:
        "Para a implementação de novos processos produtivos, custos com tercerização pode se tornar uma boa alternativa, mas com uma ferramenta de gestão de layout o tempo de resposta aos clientes e planejamento se torna mais rápido, e a empresa ficaria mais competitiva pois poderá negociar um valor atraente sem ter que custiar consultoria previamente.",
    },
    {
      text: "Possuímos uma solução que nos permite simular cenários diferentes da planta e chegar no melhor layout, processo e adequação da equipe de fabricação e montagem antes de iniciar a produção.",
      xValue: 0.75,
      yValue: 1,
      extension: {
        type: "MultipleChoice",
        title: "",
        items: [
          {
            text: "Simulação de Layout",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Simulação de Processos",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Gestão de lista de materiais de manufatura (MBOM)",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Simulação de ergonomia",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Programação e simulação de robôs",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Programação e simulação de máquinas de usinagem",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Instruções de Montagem Interativa",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Treinamento da Equipe com uso de Realidade Virtual",
            xValue: 0.01,
            yValue: 0.0,
          },
        ],
      },
      feedbackContent:
        "Para a implementação de novos processos produtivos, é sempre bom ter a disposição uma ferramenta que automatiza e prevê o melhor layout dentre os recursos disponíveis, aliviando tempo de planejamento, agilizando todo o processo de adaptação ao novo produto.",
    },
  ],
  backgroundImage: "/img/cafeteria/bg-conselho.png",
  characterName: "Renata - Diretora Comercial",
} as IQuestion;
