import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q12",
  context: [
    "Como é de conhecimento de todos, as mudanças recentes da demanda mudaram muito o nosso mix de produção.",
    "Tivemos aumento da frequência de novos produtos e/ou variações dos mesmos e, consequente, nos custos operacionais pelo impacto nos tempos de processo e não qualidade.",
    "Precisamos melhorar a nossa capacidade de entender, identificar e reagir aos fatores que estão impactando em nossos desvios operacionais para garantir o nosso nível de serviço.",
  ],
  content: [
    "Onde temos espaço para melhorar a comunicação entre as áreas que suportam o LEAN dentro do nosso processo?",
  ],
  options: [
    {
      text: "Só precisamos rever e aprimorar os nossos métodos de gestão de produção.",
      xValue: 0.25,
      yValue: 0.25,
      feedbackContent:
        "Para melhorar a comunicação e entendimento de como a empresa deverá se comportar são usados métodos de gestão de produção como Six Sigma, Lean Manufacturing, PDCA demandam tempo e não são simples de serem implementados, ter um sistema que controle e forneça indicadores de pontos de melhoria é de extrema valia para que seja constante e equivalente a evolução de todas as áreas.",
    },
    {
      text: "Temos que integrar os diferentes métodos de gestão dando sustentação ao Lean Manufacturing.",
      xValue: 0.5,
      yValue: 0.5,
      feedbackContent:
        "Investimento com integrações e melhorias na área de TI é uma boa saída, mas são custos extras que poderiam ser mitigados com técnologias já presentes no mercado, como ferramentas de gestão na nuvem, que não demandam nenhum custo a mais e não requer integração de diferentes métodos para se sustentar.",
    },
    {
      text: "Só precisamos implementar ou melhorar nossos sistemas de TI para fornecer maior visibilidade da causa raiz dos desvios e priorizar ações.",
      xValue: 0.75,
      yValue: 0.75,
      extension: {
        type: "OpenAnswer",
        title:
          "Atualmente é utilizada alguma ferramenta de TI que dê suporte aos sistemas de gestão de produção?",
      },
      feedbackContent:
        "Investimento com integrações e melhorias na área de TI é uma boa saída, mas são custos extras que poderiam ser mitigados com técnologias já presentes no mercado, como ferramentas de gestão na nuvem, que não demandam nenhum custo a mais e não requer integração de diferentes métodos para se sustentar.",
    },
    {
      text: "Nós já temos alguns sistemas inclusive para gestão do LEAN Manufacturting e precisamos aprimorar o seu uso.",
      xValue: 1,
      yValue: 1,
      extension: {
        type: "OpenAnswer",
        title:
          "Atualmente é utilizada alguma ferramenta de TI que  suporte aos sistemas de gestão de produção?",
      },
      feedbackContent:
        "O conceito de Lean Manufacturing se for seguido pode transformar uma empresa em todas as áreas, juntamente com uma ferramenta em que possa ser possível alocar todas as informações relevanter e haver uma interação dos times em prol da redução de custos, eliminar os desperdícios e manter a qualidade do produto.",
    },
  ],
  characterName: "Marcelo - Gerente de Qualidade",
  sceneNumber: 9,
} as IQuestion;
