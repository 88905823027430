import { NavLink } from "react-router-dom";
import ActionSuspense from "../../components/ActionSuspense";
import Statistic from "../../components/Statistic";
import enrollmentDatabase from "../../database/EnrollmentDatabase";
import { IEnrollment } from "../../types/EnrollmentInterface";
import usePromise from "../../utils/usePromise";

export default function Licenses() {
  const { loading, result, error } = usePromise<IEnrollment[]>(
    enrollmentDatabase.getSimulationEnrollments("3ds")
  );
  const total = result?.length;
  const complete = result?.filter((i) => i.status === "Completo").length;
  const pending = result?.filter((i) => i.status !== "Completo").length;
  return (
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <NavLink to="/reports">Relatórios</NavLink>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Uso de licenças
          </li>
        </ol>
      </nav>
      <h1 className="display-1">Licenças Utilizadas</h1>
      <div>
        <ActionSuspense loading={loading} error={error}>
          <div className="row">
            <div className="col">
              <div className="card p-2 text-center">
                <Statistic label="Total" value={total} />
              </div>
            </div>
            <div className="col">
              <div className="card p-2 text-center">
                <Statistic
                  label="Completo"
                  value={complete}
                  small={`(${((100.0 * (complete ?? 0)) / (total ?? 1)).toFixed(
                    1
                  )}%)`}
                />
              </div>
            </div>
            <div className="col">
              <div className="card p-2 text-center">
                <Statistic
                  label="Pendente"
                  value={pending}
                  small={`(${((100.0 * (pending ?? 0)) / (total ?? 1)).toFixed(
                    1
                  )}%)`}
                />
              </div>
            </div>
          </div>
        </ActionSuspense>
      </div>
    </div>
  );
}
