import { useEffect, useState } from "react";

export default function usePromise<T>(p: Promise<T>){
  const [state, setState] = useState<{
    loading: boolean;
    result?: T;
    error?: string;
  }>({
    loading: true,
    result: undefined,
    error: undefined,
  });
  useEffect(() => {
    p.then((value) => {
      setState({
        loading: false,
        result: value,
        error: undefined,
      });
    }).catch((err: Error) => {
      setState({
        loading: false,
        result: undefined,
        error: err.message || String(err),
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return state;
}