import { useState } from "react";
import Panels from "../../components/Panels";
import LoginWithEmailPassword from "./LoginWithEmailPassword";
import PasswordResetEmail from "./PasswordResetEmail";
import Register from "./Register";


export default function Login(props: { selected?: number }) {
    const [panel, setPanel] = useState(props.selected || 0);
    return (
        <div className="container">
            <hr />
            <h1>Entrar</h1>
            <hr />
            <div className="row">
                <div className="col-sm">
                    <div>
                        <p className="lead">Bem vindos as simulações de negócios 3DEXPERIENCE Game. Seu acesso aos simuladores é realizado a partir de usuário e senha.</p>
                        <p className="lead">Em todas as interfaces da simulação realizaremos um acompanhamento singular objetivando entender sua performance e desenvolvimento ao longo da atividade.</p>
                        <p className="lead">Acreditamos que a experiência com o simulador consolida conceitos e relações, equilibra soft e hard skills e prepara os profissionais para um mundo em transformação.</p>
                        <p className="lead">Aqui podemos aprimorar nossas decisões, podemos repensar nossas ações, refazer cenários, testar hipóteses e reavaliar. </p>
                        <p className="lead">O protagonismo e o accountability são essenciais, as opiniões são marcadas pela pluralidade, o ambiente é dinâmico e repleto de oportunidades. </p>
                        <p className="lead">Aproveite esse desafio e quando aparecem as dúvidas escreva para <a href="mailto:suporte@compsim.com.br">suporte@compsim.com.br</a>. </p>

                    </div>
                </div>
                <div className="col-sm">
                    <div>
                        <Panels panels={[
                            {
                                name: "Já tenho conta",
                                content: <LoginWithEmailPassword onRegister={() => setPanel(1)} onForgotPassword={() => setPanel(2)} />
                            },
                            {
                                name: "Não tenho conta",
                                content: <Register />
                            }
                            ,
                            {
                                name: "Esqueci a Senha",
                                content: <PasswordResetEmail />,
                                visible: false
                            }
                        ]}
                            selected={panel}
                            onSelect={setPanel} />

                    </div>
                </div>
            </div>
        </div>
    );
}