import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q7",
  context: [
    "Recebemos aqui no comitê de Sustentabilidade o questionamento de um cliente sobre nossa visão à cerca de certificações como ISO 14.000 e ações internas que visem redução de impacto ambiental.",
  ],
  content: [
    "Por este motivo, estou coletando com todas as áreas quais suas iniciativas para este tema?",
  ],
  options: [
    {
      text: "Infelizmente, de maneira geral até o momento a empresa não está conseguindo medir nosso impacto ambiental e precisaremos do Comitê para atacar este problema.",
      xValue: 0,
      yValue: 0,
      feedbackContent:
        "É de suma importância cumprir legislações e certificações padronizadas de qualidade no mundo inteiro. Sustentabilidade é uma pauta muito presente e seria importante mensurar o impacto que a empresa tem, a fim de sempre buscar reduzir a poluição, proporcionando o equilibrio e proteção do meio ambiente.",
    },
    {
      text: "No meu departamento conseguimos medir o impacto que estamos tendo, porém sei que muitas áreas não conseguem ter a mesma mensuração.",
      xValue: 0.25,
      yValue: 0.5,
      extension: {
        type: "OpenAnswer",
        title:
          "Você poderia me contar um pouco mais sobre como você faz esse processo de medição de impacto ambiental na sua área? Que tipo de dados e relatórios você consegue extrair?",
      },
      feedbackContent:
        "É interessante mplementar o controle de impacto ambiental dentro de alguns setores da empresa, mas o ideal é sempre buscar trabalhar de forma sustentável em todos as áreas e durante todo o processo de desenvolvimento de produto.",
    },
    {
      text: "Podemos medir o impacto desde a extração da matéria prima, resíduos, etc, até a entrega final do produto.",
      xValue: 0.75,
      yValue: 0.75,
      extension: {
        type: "OpenAnswer",
        title:
          "Você poderia me contar um pouco mais sobre como você faz esse processo de medição de impacto ambiental na sua área? Que tipo de dados e relatórios você consegue extrair?",
      },
      feedbackContent:
        "Medir o impacto ambiental e ser uma empresa certificada ISO 14.000 traz credibilidade frente à outras empresas que possam ser possíveis parceiras, frente aos clientes e ajuda o planeta se tornar um lugar menos poluído.",
    },
  ],
  characterName: "Marcondes - Gerente de Sustentabilidade",
  sceneNumber: 4,
} as IQuestion;
