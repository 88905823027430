export default function Statistic(props: {
  label: string;
  value?: string | number;
  small?: string | number;
}) {
  const { label, value, small } = props;
  return (
    <div>
      <div className="display-6">
        {value} <small>{small}</small>
      </div>
      <div>{label}</div>
    </div>
  );
}
