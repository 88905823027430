import { ReactNode } from "react";

export default function ButtonsWrapper(props: { children: ReactNode }) {
    return (
      <div
        style={{
          position: "absolute",
          left: "0",
          right: "0",
          bottom: "0px",
          padding: "10px",
          height: "60px",
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          justifyContent: "center",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
        }}
      >
        {props.children}
      </div>
    );
  }
  
  