import { orderBy, where } from "firebase/firestore";
import {
  firebaseGet,
  firebaseInsert,
  firebaseQuery,
  firebaseUpdate,
} from "../Firebase";
import { IEnrollment } from "../types/EnrollmentInterface";

const ENROLLMENT_COLLECTION = "enrollment";

class EnrollmentDatabase {
  async getEnrollment(id: string): Promise<IEnrollment | undefined> {
    if (!id) return undefined;
    return firebaseGet<IEnrollment>(ENROLLMENT_COLLECTION, id);
  }

  async updateEnrollment(enrollment: IEnrollment): Promise<void> {
    enrollment.updatedAt = new Date().toISOString();
    const { id, ...updateData } = enrollment;
    return firebaseUpdate(ENROLLMENT_COLLECTION, updateData as any, id);
  }

  async insertEnrollment(
    simulationId: string,
    email: string
  ): Promise<IEnrollment> {
    const enrollment: IEnrollment = {
      id: "",
      createdAt: new Date().toISOString(),
      simulationId: simulationId,
      email: email,
      status: "Novo",
      answers: [],
    };
    const { id, ...insertData } = enrollment;
    const docRef = await firebaseInsert(
      ENROLLMENT_COLLECTION,
      insertData as any
    );
    enrollment.id = docRef.id;
    return enrollment;
  }

  async getUserSimulationEnrollments(email: string, simulationId: string): Promise<IEnrollment[]> {
    return firebaseQuery<IEnrollment>(
      ENROLLMENT_COLLECTION,
      where("email", "==", email),
      where("simulationId", "==", simulationId),
      orderBy("createdAt", "desc")
    );
  }

  async reset(enrollment: IEnrollment) {
    if (!enrollment) return Promise.resolve();
    enrollment.answers = [];
    enrollment.progress = undefined;
    enrollment.status = "Novo";
    enrollment.trackName = undefined;
    enrollment.updatedAt = new Date().toISOString();
    return this.updateEnrollment(enrollment);
  }

  async getSimulationEnrollments(simulationId: string): Promise<IEnrollment[]>{
    return firebaseQuery<IEnrollment>(
      ENROLLMENT_COLLECTION,
      where("simulationId", "==", simulationId),
      orderBy("updatedAt", "desc")
    ); 
  }
}

const enrollmentDatabase = new EnrollmentDatabase();
export default enrollmentDatabase;
