import bsIcons from "bootstrap-icons/bootstrap-icons.svg";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { IScenePosition } from "../../database/SceneDatabase";
import { questionState } from "../../services/Question";

export default observer(function TextPopper(props: {
  position: IScenePosition;
  content: string;
  title: string;
  onClick?: () => void;
  width?: string | number;
  placement?: string;
  visible: boolean;
}) {
  const {
    position,
    content,
    title,
    visible,
    width,
    onClick,
    placement = "top",
  } = props;
  const wrapperRef = useRef(null);
  const expectX = (questionState.windowWidth / 1920) * position.X;
  const expectY = (questionState.windowHeight / 1080) * position.Y;
  useEffect(() => {
    const wrapper = wrapperRef.current;
    const { bootstrap } = window as any;
    const popover = new bootstrap.Popover(wrapper, {
      title: title,
      trigger: "manual",
      content: content,
      placement: placement,
      html: true,
      width: width,
      options: {
        mainAxis: false, // true by default
        adaptive: false,
        modifiers: [{ name: "eventListeners", enabled: false }],
      },
    });
    if (visible) {
      popover.show();
    }
    return () => popover.hide();
  });
  return (
    <div
      style={{
        position: "absolute",
        left: expectX,
        bottom: expectY,
        cursor: "pointer",
      }}
      ref={wrapperRef}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (onClick) onClick();
      }}
    >
      <svg className="bi" width="32" height="32" fill="currentColor">
        <use xlinkHref={`${bsIcons}#lightbulb`} />
      </svg>
    </div>
  );
});
