import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q2",
  characterName: "Renata - Diretora Comercial",
  sceneNumber: 3,
  context: [
    "Surgiu uma oportunidade num cliente na Europa que exige comprovação de como fazemos para ter visibilidade em tempo real sobre a evolução do projeto.",
  ],
  content: ["Como podemos atender a essa exigência?"],
  options: [
    {
      text: "Em nossa política de trabalho, buscamos o escopo do projeto, criamos o produto e ao final do trabalho enviamos ao cliente para que ele faça a aprovação final, caso haja algum ajuste, podemos fazê-lo.",
      xValue: 0.25,
      yValue: 0.25,
      feedbackContent:
        "Para o cliente ter visibilidade em tempo real é crucial que haja a interação com ele em todo o processo de criação e desenvolvimento do produto, a validação e comunicação precisa ser constante a fim de evitar retrabalhos.",
    },
    {
      text: "Em tempo real não podemos fazer, porém, podemos enviar periodicamente via e-mail o status do projeto.",
      xValue: 0.5,
      yValue: 0.25,
      feedbackContent:
        "Para o cliente ter visibilidade em tempo real é crucial que haja a interação com ele em todo o processo de criação e desenvolvimento do produto, a validação e comunicação precisa ser constante a fim de evitar retrabalhos.",
    },
    {
      text: "Podemos falar com a TI para dar um acesso ao nosso servidor e liberar uma pasta com os PDFs do projeto.",
      xValue: 0.75,
      yValue: 0.5,
      feedbackContent:
        "Para o cliente ter visibilidade, dar acesso a uma pasta do servidor é uma alternativa válida, mas ainda considerada engessada, a visibilidade em tempo real aumenta a assertividade e as requisições do cliente serão atendidas mais rápidas, como mudanças no projeto.",
    },
    {
      text: "Daremos acesso ao nosso sistema de gestão de projetos que fica na nuvem e de lá o cliente terá uma visão em tempo real do seu projeto.",
      xValue: 1,
      yValue: 1,
      extension: {
        type: "OpenAnswer",
        title: "Qual ferramenta vc utiliza? (escreva)",
      },
      feedbackContent:
        "Dar acesso para o cliente ao sistema de gestão na nuvem se torna a opção que consegue atingir um nível de satisfação imensa, sendo acessível por todos e à todo momento, habilitando a visualização do projeto e feedback instantâneo.",
    },
  ],
} as IQuestion;
