import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { ReactNode } from "react";
import AppLayout from "../../components/AppLayout";
import TrackSelect from "./TrackSelect";

class IntroState {
    current = 0;
    get hasPrevious() {
        return this.current > 0;
    }
    get hasNext() {
        return this.current < 3;
    }
    next() {
        this.current++;
    }
    previous() {
        this.current--;
    }
    constructor() {
        makeAutoObservable(this);
    }
}
const introState = new IntroState();

const IntroItem = observer((props: { children: ReactNode }) => {

    return (
        <div className="centered-blurred-message fade-in">
            {props.children}
            <div style={{
                display: "flex",
                gap: "10px",
                justifyContent: "center"
            }}>
                {introState.hasPrevious ? (
                    <button className="btn btn-secondary" onClick={() => introState.previous()}>
                        &laquo; Voltar
                    </button>
                ) : null}

                <button className="btn btn-primary" onClick={() => introState.next()}>
                    Avançar &raquo;
                </button>
            </div>
        </div>
    );
});

function Intro1() {
    return (
        <IntroItem>
            <h1>3DEXPERIENCE Game</h1>
            <hr />
            <div>
                <p className="lead">
                    Sua empresa, após esses anos de Pandemia, vem buscando maneiras de crescer e recuperar o ritmo e o cenário agora é muito mais desafiador.
                </p>
                <hr />
            </div>
            <div>
                <p className="lead">
                    Sua empresa enfrenta uma mudança no perfil de clientes,
                    (com perda de alguns clientes recorrentes e surgimento de novas oportunidades).
                </p>
                <hr />
            </div>
        </IntroItem>
    );
}

function Intro2() {
    return (
        <IntroItem>
            <div>
                <p className="lead">
                    O impacto financeiro de todos os envolvidos foi grande e temas como: redução de custos,
                    agilidade e flexibilidade na maneira de trabalhar se tornam pontos importantes.
                </p>
                <hr />
            </div>
            <div>
                <p className="lead">
                    O mercado vem demandando novos produtos e serviços
                    e seu departamento será cada vez mais pressionado a atender esse pedidos.
                </p>
                <hr />
            </div>
            <div>
                <p className="lead">
                    Alguns clientes de geografias nunca antes acessadas passaram a contactar a
                    sua empresa para entender se vocês estão aptos a atendê-los.
                </p>
                <hr />
            </div>
        </IntroItem>
    );
}

function Intro3() {
    return (
        <IntroItem>
            <div>
                <p className="lead">
                    Levando em conta este desafio, responda às perguntas que vão surgir seguindo
                    o perfil atual da sua empresa e a forma como vocês reagem frente aos desafios.
                </p>
                <hr />
            </div>
            <div>
                <p className="lead">
                    Não considere como você desejaria que fosse feito, mas a maneira que é feito atualmente.
                </p>
                <hr />
            </div>
            <div>
                <p className="lead">
                    Vá em frente e escolha entre a equipe de engenharia ou de manufatura para continuar.
                </p>
                <hr />
            </div>
        </IntroItem>
    );
}

const IntroductionContent = observer(() => {
    switch (introState.current) {
        case 0: return <Intro1 />;
        case 1: return <Intro2 />;
        case 2: return <Intro3 />;
        default: return <TrackSelect />;
    }
});

export default function Introduction() {
    return (
        <AppLayout
            backgroundImg="/img/bg-office.jpg"
            justifyContent="center"
            alignItems="center">
            <IntroductionContent />
        </AppLayout>
    );
}