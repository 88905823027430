import { ChangeEventHandler, ReactNode } from "react";

export function TextField(props: {
  name: string;
  title: string;
  target: Record<string, string>;
  disabled?: boolean;
  type?: string;
  placeholder?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  autoFocus?: boolean;
}) {
  const {
    disabled = false,
    type,
    placeholder,
    name,
    title,
    target,
    required,
  } = props;
  return (
    <>
      <div className="form-floating">
        <input
          type={type}
          disabled={disabled}
          className="form-control"
          required={required}
          id={name}
          placeholder={placeholder}
          onChange={(e) => (target[name] = e.target.value)}
          minLength={props.minLength}
          maxLength={props.maxLength}
          autoFocus={props.autoFocus}
        />
        <label htmlFor={name}>{title}</label>
      </div>
      <br />
    </>
  );
}

export function Checkbox(props: {
  name: string;
  title: ReactNode;
  target: Record<string, string>;
  required?: boolean;
  disabled?: boolean;
}) {
  const { name, title, target, required, disabled } = props;
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    target[name] = e.target.checked ? "Sim" : "Não";
  };
  return (
    <>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value="Sim"
          id={name}
          name={name}
          onChange={onChange}
          required={required}
          disabled={disabled}
        />
        <label className="form-check-label" htmlFor={name}>
          {title}
        </label>
      </div>
    </>
  );
}

export function Searchbox(props: {
  name: string;
  title: string;
  onChange: (value: string) => void;
  placeholder?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
}) {
  const {
    name,
    title,
    placeholder,
    autoFocus,
    disabled,
    required,
    minLength,
    maxLength,
    onChange,
  } = props;
  const changeValue: ChangeEventHandler<HTMLInputElement> = (e) => {
    const v = e.target.value;
    onChange(v);
  };
  return (
    <div className="form-floating">
      <input
        type="text"
        disabled={disabled}
        className="form-control"
        required={required}
        id={name}
        placeholder={placeholder}
        onChange={changeValue}
        minLength={minLength}
        maxLength={maxLength}
        autoFocus={autoFocus}
      />
      <label htmlFor={name}>{title}</label>
    </div>
  );
}
