import AppLayout from "../../components/AppLayout";

export default function About() {
    return (
        <AppLayout justifyContent="flex-start" alignItems="flex-start">
            <div className="container">
                <hr />
                <h1>Sobre o 3DEXPERIENCE Game</h1>
                <hr />
                <p className="lead">
                O 3DEXPERIENCE Game tem como objetivo ajudar os jogadores a entenderem o atual 
                cenário em que eles se encontram no seu dia a dia dentro de sua empresa, através 
                de uma atividade lúdica.
                Através do 3DEXPERIENCE Game, o jogador terá uma análise macro de como sua empresa 
                se encontra em termos de Produtividade e Inovação e o diagnóstico completo, ajudará 
                a equipe da DASSAULT SYSTEMES e parceiros a traçar pontos de melhorias e como 
                potencializar ainda mais as qualidades já existentes na empresa.
                Bom jogo! 
                </p>
                <hr />
                <div className="row g-5">
                    <div className="col-md-6">
                        <h2>Realização</h2>
                        <p>Este game é um projeto colaborativo entre a DASSAULT SYSTEMES e a Compsim Brasil.</p>
                        <ul className="icon-list ps-0">
                            <li className="text-muted d-flex align-items-start mb-1"></li>
                        </ul>
                    </div>

                    <div className="col-md-6">
                        <h2>Ajuda</h2>
                        <p>Para conseguir ajuda para este simulador temos os seguintes pontos de contato:</p>
                        <ul className="icon-list ps-0">
                            <li className="d-flex align-items-start mb-1"><a href="mailto:suporte@compsim.com.br">Suport Compsim</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}