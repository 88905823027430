import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q6",
  context: [
    "O time comercial está projetando um crescimento de 20% na demanda de pedidos dentro de 3 meses.",
  ],
  content: [
    "Como podemos garantir que estaremos prontos para absorver esse crescimento em um prazo tão curto?",
    "Temos como avaliar se existe mão de obra, insumos e recursos disponíveis?",
  ],
  options: [
    {
      text: "Podemos fazer uma análise, porém levaremos alguns dias para concluir e apresentar um resultado para discussão.",
      xValue: 0,
      yValue: 0,
      feedbackContent:
        "Para prever possíveis crescimentos do mercado não se pode depender de análises que demandam tempo para serem concluídas, isso pode acarretar em um gap em relação as tendências que surgem, pois empresas que obtem informações em tempo real sobre demanda, direção do mercado e produtos novos que chegam, acabam arrancando na frente no desenvolvimento e na preparação do seu time.",
    },
    {
      text: "Sim, o nosso ERP nos auxilia na projeção de compra de insumos, porém teremos que simular a capacidade da mão de obra e maquinário em planilhas auxiliares para analisarmos se teremos sobrecarga.",
      xValue: 0.35,
      yValue: 0.35,
      feedbackContent:
        "Para prever possíveis crescimentos do mercado, o auxílio de um ERP significa um grande passo, mas não preenche todas as lacunas para ter um overview completo. A adesão de uma solução que possibilita criar diversos cenários possíveis pode preparar todo time para suportar eventuais picos de demanda, sem que haja surpresas.",
    },
    {
      text: "Sim, temos uma solução que nos permite simular diferentes cenários de crescimento projetando o uso de cada recurso necessário.",
      xValue: 0.8,
      yValue: 0.8,
      feedbackContent:
        "Prever e simular situações de crescimento de demanda é essencial para uma empresa, estar atento as tendências é fruto de ferramentas que disponibilizam as informações corretas a fim de utilizar os recursos da melhor forma.",
    },
  ],
  backgroundImage: "/img/cafeteria/bg-sala.jpg",
  characterName: "Antonio - Gerente de Planejamento",
  sceneNumber: 1,
} as IQuestion;
