import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q5",
  context: [
    "O time comercial me chamou para uma reunião onde foi discutido um potencial cliente americano, muito diferente do que estamos habituados, porém, extremamente promissor.",
    "Este cliente quer trazer um produto novo e na discussão com o time comercial surgiu a dúvida se o nosso processo atual demandará a criação de diversos protótipos físicos, o que pode acarretar no aumento do valor da proposta devido aos custos gerados.",
  ],
  content: [
    "Nós temos como atender esse projeto sem precisar de muitos protótipos físicos?",
  ],
  options: [
    {
      text: "Internamente trabalhamos com os cálculos e algumas empresas parceiras fazem os protótipos físicos. Teremos que consultá-las para verificar os custos.",
      xValue: 0.25,
      yValue: 0.25,
      feedbackContent:
        "Para atender projetos sem precisar de muitos protótipos é preciso entender que quando terceirizado se torna uma etapa mais complexa manter o padrão de qualidade, gestão do tempo do projeto e certificação do cumprimento dos requisitos, sem incluir o custo adicional pelo serviço prestado.",
    },
    {
      text: "Não, a única forma de executar esses testes é fazendo os protótipos físicos internamente.",
      xValue: 0,
      yValue: 0.25,
      feedbackContent:
        " Fazer protótipos físicos impactam diretamente no desenvolvimento e principalmente na sustentabilidade, atualmente existem técnologias para realizar simulações que auxiliam na checagem de requisitos, reduzindo drasticamente a criação de protótipos, que elevam absurdamente o custo do projeto e o tempo de lançamento do produto no mercado.",
    },
    {
      text: "Para evitar os altos custos com protótipos físicos, podemos terceirizar a criação de protótipos virtuais. Mesmo assim, teremos um custo adicional e um tempo de projeto mais longo.",
      xValue: 0.5,
      yValue: 0.75,
      feedbackContent:
        "Para atender projetos sem precisar de muitos protótipos é preciso entender que quando terceirizado se torna uma etapa mais complexa manter o padrão de qualidade, gestão do tempo do projeto e certificação do cumprimento dos requisitos, sem incluir o custo adicional pelo serviço prestado.",
    },
    {
      text: "Temos uma solução para desenvolvimento de protótipos virtuais que reduz drasticamente o tempo do projeto e a necessidade de protótipos físicos.",
      xValue: 1,
      yValue: 1,
      extension: {
        type: "OpenAnswer",
        title:
          "Qual a solução utilizada para desenvolvimento de protótipos virtuais?",
      },
      feedbackContent:
        "Possuir uma solução completa que possa ser feito o design e todas as simulações (Estruturais, Fluidos, Impacto) é que vão trazer confiabilidade para a não construção de um protótipo físico, quanto mais robusta a solução, o produto é desenvolvido em menos tempo e fornece compensação financeira.",
    },
  ],
  backgroundImage: "/img/cafeteria/bg-entrada.jpg",
  characterName: "Melissa - Gerente de Engenharia",
  sceneNumber: 6,
} as IQuestion;
