import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q1",
  characterName: "Fernando - Presidente Executivo",
  sceneNumber: 2,
  context: [
    "Srs, como vocês sabem, nos últimos tempos, perdemos clientes importantes e estamos buscando novas oportunidades.",
    "Precisamos estabelecer uma maneira de nos comunicar de forma eficaz e rápida para responder às oportunidades que estão surgindo.",
  ],
  content: ["Gostaria de saber como estamos fazendo isso atualmente."],
  options: [
    {
      text: "Reunimos a equipe semanalmente para atualização das oportunidades",
      xValue: 0.25,
      yValue: 0.25,
      feedbackContent:
        "A reunião do time para discussões é muito importante, mas o uso de uma ferramenta de comunicação pode levar informações e decisões em tempo real para todos os colaboradores necessários e diminuir a atividade de se reunir para atualizações e definições em relação a empresa ou projeto.",
    },
    {
      text: "Temos grupo de whatsapp que facilita a interação e a comunicação entre o time",
      xValue: 0.75,
      yValue: 0.5,
      feedbackContent:
        "O uso de ferramentas de comunicação entre o time como Whatsapp e E-mail já significa um avanço, mas seria interessante a adoção de ferramentas que sejam integradas com seu processo de desenvolvimento de produto.",
    },
    {
      text: "Trocamos e-mails a cada nova oportunidade surgida",
      xValue: 0.5,
      yValue: 0.25,
      feedbackContent:
        "O uso de ferramentas de comunicação entre o time como Whatsapp e E-mail já significa um avanço, mas seria interessante a adoção de ferramentas que sejam integradas com seu processo de desenvolvimento de produto.",
    },
    {
      text: "Temos uma ferramenta dedicada que facilita a comunicação, colaboração e gestão de novas oportunidades",
      xValue: 1,
      yValue: 1,
      extension: {
        type: "OpenAnswer",
        title: "Qual ferramenta vc utiliza? (escreva)",
      },
      feedbackContent:
        "Ter uma boa comunicação e colaboração do time é de extrema importância, pois evita dúvidas na realização da tarefa e todos estarão cientes do progresso do projeto.",
    },
  ],
} as IQuestion;
