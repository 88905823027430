import { ChangeEventHandler, useState } from "react";
import { NavLink } from "react-router-dom";
import ActionSuspense from "../../components/ActionSuspense";
import NineBox from "../../components/NineBox";
import enrollmentDatabase from "../../database/EnrollmentDatabase";
import enrollmentState, {
  calcEnrollmentX,
  calcEnrollmentY,
} from "../../services/Enrollment";
import { IEnrollment } from "../../types/EnrollmentInterface";
import { formatPercentage } from "../../utils/Strings";
import usePromise from "../../utils/usePromise";

const renderEnrollment = (enrollment: IEnrollment, idx: number) => {
  return (
    <tr key={idx}>
      <td>
        <NavLink to={`../enrollment-view/${enrollment.id}`}>
          {enrollment.email}
        </NavLink>
      </td>
      <td>{enrollment.eventName}</td>
      <td>{enrollment.createdAt}</td>
      <td>{enrollment.trackName}</td>
      <td>{enrollment.status}</td>
      <td>{formatPercentage(enrollment.progress, 1)}</td>
      <td>{enrollment.updatedAt}</td>
    </tr>
  );
};

function EventFilter(props: {
  events?: string[];
  onChange: (eventName: string) => void;
}) {
  const changeSelect: ChangeEventHandler<HTMLSelectElement> = (e) =>
    props.onChange(e.target.value);
  return (
    <div style={{ width: "200px" }}>
      <select
        className="form-select"
        aria-label="Filter Events"
        onChange={changeSelect}
      >
        <option value="">Filter Events</option>
        {props.events?.map((ev) => (
          <option key={ev} value={ev}>
            {ev}
          </option>
        ))}
      </select>
    </div>
  );
}

export default function Enrollments() {
  const { loading, result, error } = usePromise<IEnrollment[]>(
    enrollmentDatabase.getSimulationEnrollments("3ds")
  );

  const [filterEvent, setFilterEvent] = useState("");

  const events = result?.reduce((eventArray, current) => {
    const eventName = current.eventName ?? undefined;
    if (eventName && eventArray.indexOf(eventName) < 0)
      eventArray.push(eventName);
    return eventArray;
  }, [] as string[]);

  const filteredResults = filterEvent
    ? result?.filter((r) => r.eventName === filterEvent)
    : result;

  const points = filteredResults
    ?.filter((r) => r.status === "Completo")
    .map((r) => [calcEnrollmentX(r), calcEnrollmentY(r)]);

  if (!enrollmentState.globalAverage && points) {
    const filteredPoints = points.filter((p) => p[0] <= 1 && p[1] <= 1);
    const sum = filteredPoints.reduce(
      (sum, curr) => {
        sum[0] += curr[0];
        sum[1] += curr[1];
        return sum;
      },
      [0, 0]
    );
    const avg = sum.map((v) => v / filteredPoints.length);
    //console.log("avg", avg, sum, filteredPoints.length);
    //console.log(filteredPoints);
    enrollmentState.setGlobalAverage(avg);
  }

  return (
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <NavLink to="/reports">Relatórios</NavLink>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Inscrições
          </li>
        </ol>
      </nav>
      <div className="hstack gap-3">
        <h1 className="display-1 me-auto">Inscrições</h1>
        <EventFilter events={events} onChange={setFilterEvent} />
      </div>

      <ActionSuspense loading={loading} error={error}>
        {points ? <NineBox points={points} legendText={"Respostas"} /> : null}
        <table className="table">
          <thead>
            <tr>
              <th>E-mail</th>
              <th>Evento</th>
              <th>Criado Em</th>
              <th>Trilha</th>
              <th>Situação</th>
              <th>Progresso</th>
              <th>Ult. Atualização</th>
            </tr>
          </thead>
          <tbody>{filteredResults?.map(renderEnrollment)}</tbody>
        </table>
      </ActionSuspense>
    </div>
  );
}
