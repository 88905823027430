import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q10",
  context: [
    "Estamos com uma oportunidade de ganhar um grande cliente do nosso principal concorrente, porém, um ponto crucial para eles é a validação virtual de alguns aspectos da utilização dos produtos tais como: Esforços Mecânicos e Comportamento de Fluídos.",
  ],
  content: ["Temos como fazer esse tipo de validação virtual?"],
  options: [
    {
      text: "Infelizmente não, atualmente fazemos apenas cálculos manuais como parte do nosso processo de desenvolvimento.",
      xValue: 0,
      yValue: 0,
      feedbackContent:
        "Fazer validações manuais além de ser uma atividade muito complexa e que pode demandar um atraso na entrega do projeto, o risco de falha é muito alto, podendo trazer complicações e resultar em retrabalho. Atualmente é indispensável pra ser competitivel no mercado ter a disposição ferramentas de simulação, principalmente Esforços Mecânicos e Comportamento de Fluídos.",
    },
    {
      text: "Temos apenas ferramentas para simulações básicas. Não conseguimos chegar ao nível de complexidade exigida.",
      xValue: 0.5,
      yValue: 0.25,
      feedbackContent:
        "É muito importante que se tenha esssa mentalidade inovadora e sustentável, mas investir em um nível de precisão com simulações avançadas sem a necessidade de custos extras seria o ideal, deter o controle de todo o processo de desenvolvimento desde a criação do design, testes de durabilidade e qualidade, até a entrega desse produto.",
    },
    {
      text: "Quando necessário usamos um parceiro externo para tocar esse tipo de demanda",
      xValue: 0.25,
      yValue: 0.5,
      feedbackContent:
        "É muito importante que se tenha esssa mentalidade inovadora e sustentável, mas investir em um nível de precisão com simulações avançadas sem a necessidade de custos extras seria o ideal, deter o controle de todo o processo de desenvolvimento desde a criação do design, testes de durabilidade e qualidade, até a entrega desse produto.",
    },
    {
      text: "Sim! Podemos fazer todas as validações exigidas.",
      xValue: 1,
      yValue: 1,
      feedbackContent:
        "Empresas que validam seus produtos com simulação reduzem absurdamente o custo com protótipos, atingem um nível de sustentabilidade alto, pois não geram resíduos e não poluem, reduzem o tempo de chegada do produto ao mercado, sem contar a oportunidade de ter relátorios sobre as característica do produto.",
    },
  ],
  backgroundImage: "/img/cafeteria/bg-scene-gray-room.jpg",
  characterName: "Dolores - Diretora de Vendas",
  sceneNumber: 11,
} as IQuestion;
