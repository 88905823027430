import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q8-e",
  context: [
    "Estamos com um projeto para redução de custos e tempo de atendimento para as atividades de manutenção. Dois pontos críticos para esse tema são os alto custos de translado de profissionais qualificados para atender,",
    "chamados de manutenções básicas bem como a criação e tradução para diversos idiomas dos manuais impressos com os procedimentos de manutenção.",
  ],
  content: [
    "Atualmente existe alguma iniciativa para otimizar a criação dos procedimentos de manutenção de forma a nos ajudar na otimização dos pontos citados?",
  ],
  options: [
    {
      text: "Não, hoje criamos todos os procedimentos de manutenção da forma tradicional, ou seja, são impressos utilizando fotos dos equipamentos e traduzimos os documentos para cada idioma solicitado.",
      xValue: 0,
      yValue: 0,
      feedbackContent:
        "Dentro da proposta de otimização, não ter as documentações e a propriedade intelectual disponíveis em nuvem para os colaboradores interessados dificulta a execução do trabalho, e  manter manuais impressos implica em certificações sobre meio ambiente, sustentabilidade, que podem abrir muitas oportunidades no futuro.",
    },
    {
      text: "Estamos adotando a substituição das fotos por imagens dos projetos 3D, isso agiliza um pouco a criação dos documentos.",
      xValue: 0.25,
      yValue: 0.25,
      feedbackContent:
        "A iniciativa de substituir por imagens dos projetos 3D ainda é um processo engessado, a transferência de dados ainda ser manual impacta muito na produtividade e na disponibilização de um documento tão importante.",
    },
    {
      text: "Utilizamos uma ferramenta especifica que aproveita os dados 3D da engenharia, isso agiliza bastante a criação e atualização dos documentos, mas a colaboração desses dados ainda é uma preocupação.",
      xValue: 0.5,
      yValue: 0.75,
      feedbackContent:
        "A iniciativa de aproveitar os dados de Engenharia agiliza muito o fluxo de trabalho, mas se outros colaboradores não conseguirem ajudar na criação, visualizar em tempo real, ou o compartilhamento ser burocrático, continua sendo um ponto de gargalo no desenvolvimento de produto.",
    },
    {
      text: "Utilizamos uma ferramenta especifica que aproveita os dados 3D da engenharia, isso agiliza bastante a criação e atualização dos documentos bem como permite a criação de procedimentos interativos, reduzindo a necessidade de manuais impressos. Todos esses dados são integrados ao nosso ambiente em nuvem, permitindo o consumo e colaboração de forma muito mais rápida e dinâmica.",
      xValue: 0.75,
      yValue: 1,
      extension: {
        type: "MultipleChoice",
        title: "",
        items: [
          {
            text: "Simulação de Layout",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Simulação de Processos",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Gestão de lista de materiais de manufatura (MBOM)",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Simulação de ergonomia",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Programação e simulação de robôs",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Programação e simulação de máquinas de usinagem",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Instruções de Montagem Interativa",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Treinamento da Equipe com uso de Realidade Virtual",
            xValue: 0.01,
            yValue: 0.0,
          },
        ],
      },
      feedbackContent:
        "A iniciativa de aproveitar os dados de Engenharia, conseguir ter acesso a essas informaçoes a todo tempo, visualizar e colaborar em tempo real tendo um repositório na nuvem com todos esses componentes é o caminho ideal pra quem quer ser mais produtivo.",
    },
  ],
  characterName: "Cristiane - Gerente de Controladoria",
  sceneNumber: 7,
} as IQuestion;
