import { useState } from "react";
import { InputBox } from "../../components/Inputs";
import { NavLink } from "react-router-dom";

export default function LinkGenerator() {
  const [eventName, setEventName] = useState("");
  let link = window.location.href;
  link = link.substring(0, link.indexOf("/reports"));
  link += `/simulation/#${eventName.replaceAll(" ", "-")}`;
  return (
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <NavLink to="/reports">Relatórios</NavLink>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Gerador de links
          </li>
        </ol>
      </nav>
      <h1 className="display-1">Relatórios</h1>
      <div>
        <div className="card p-2">
          <InputBox
            label="Qual é o nome do evento para o link?"
            value={eventName}
            state={eventName ? "valid" : "invalid"}
            onChange={setEventName}
          />
          <p className="p-4">
            <a href={link} target="_blank" rel="noreferrer">
              {link}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
