export default function AltaProdutividadeAltaInovacao(){
    return (
      <div>
        <h1 className="display-1">Alta Produtividade, Alta Inovação</h1>
        <p className="lead">
        Baseado nas decisões tomadas durante o jogo, sua empresa tem atendido 
        as demandas atuais do mercado em termos de produtividade. <br />
        Além disso, o alto investimento na inovação de seus processos, 
        indicada pelas suas ações durante jogo, indica que seus custos atuais 
        para manter essa produtividade são bem dimensionados e demonstram 
        sustentabilidade em seus negócios. <br />
        Além disso, a entrada em novos 
        mercados pode estar vinculada a capacidade de inovação de seus processos.
        </p>
      </div>
    );
}