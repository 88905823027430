import { observer } from "mobx-react-lite";
import { NavLink } from "react-router-dom";
import { authenticationState } from "../services/Authentication";
import enrollmentState from "../services/Enrollment";

const NavProgress = observer(() => {
  const { enrollment, currentProgress } = enrollmentState;
  const pctProgress = Math.round(100.0 * currentProgress);
  return enrollment && currentProgress > 0 && pctProgress < 100 ? (
    <div style={{ width: "50%" }}>
      <div>Progresso na Simulação:</div>
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          aria-label="Simulation Progress"
          style={{ width: `${pctProgress}%` }}
          aria-valuenow={pctProgress}
          aria-valuemin={0}
          aria-valuemax={100}
        >
          {`${pctProgress}%`}
        </div>
      </div>
    </div>
  ) : null;
});

export default observer(function AppNav() {
  const { user } = authenticationState;

  return (
    <nav
      className="navbar navbar-expand-lg"
      style={{ backgroundColor: "rgba(255, 255, 255, 0.5)", boxShadow: "none" }}
    >
      <div className="container">
        <NavLink className="navbar-brand" to="/">
          <img src="/img/logo-3ds.svg" alt="" />
        </NavLink>
        <NavProgress />
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
        >
          <ul className="nav">
            <li className="nav-item">
              <NavLink to="/about" className="nav-link">
                Sobre este projeto
              </NavLink>
            </li>
            <li className="nav-item">
              {user ? (
                <NavLink to="/profile" className="nav-link">
                  {authenticationState.displayName}
                </NavLink>
              ) : (
                <NavLink to="/profile" className="nav-link active">
                  Entrar
                </NavLink>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
});
