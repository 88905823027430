import * as bootstrap from "bootstrap";
import { ReactNode, useEffect, useRef, useState } from "react";
export default function Modal(props: {
  visible: boolean;
  title?: string;
  footer?: ReactNode;
  children: ReactNode;
}) {
  const { visible } = props;
  const modalRef = useRef(null);
  const [modal, setModal] = useState<bootstrap.Modal>();
  useEffect(() => {
    if (modalRef.current) {
      const myModal = new bootstrap.Modal(modalRef.current);
      setModal(myModal);
      return () => myModal.dispose();
    }
  }, [modalRef]);
  useEffect(() => {
    if (visible) modal?.show();
    else modal?.hide();
  });
  return (
    <div
      className="modal fade"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-hidden="true"
      ref={modalRef}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{props.title}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">{props.children}</div>
          <div className="modal-footer">{props.footer}</div>
        </div>
      </div>
    </div>
  );
}
