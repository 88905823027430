import { observer } from "mobx-react-lite";
import ActionSuspense from "../../components/ActionSuspense";
import { authenticationState, resendVerificationEmail } from "../../services/Authentication";


export default observer(function VerifyEmail() {

    return (
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <div>
              <h1 style={{ marginBottom: "20px" }}>Bem vindo!</h1>
            </div>
          </div>
          <div className="col-sm">
            <p>Você ainda precisa verificar o seu endereço de e-mail.</p>
            <p>Vá até a sua caixa de entrada e clique no link de validação.</p>
            <p>Não recebeu o link?</p>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => window.location.reload()}
            >
              Atualizar
            </button>
            &nbsp;
            <button
              type="button"
              className="btn btn-secondary"
              onClick={resendVerificationEmail}
            >
              Reenviar
            </button>
            <ActionSuspense
              loading={authenticationState.authLoading}
              error={authenticationState.authError}
              success={authenticationState.authSuccess}
            />
          </div>
        </div>
      </div>
    );
});