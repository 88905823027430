import { allQuestions } from "../../database/QuestionDatabase";
import {
  IEnrollment,
  IEnrollmentAnswer,
} from "../../types/EnrollmentInterface";

function renderAnswer(answer: IEnrollmentAnswer, idx: number) {
  const question = allQuestions.find((q) => q.questionId === answer.questionId);
  return (
    <tr key={idx}>
      <td>
        <b>{question?.characterName}</b>
        <div>{question?.context?.join(" ")}</div>
        <h5>{question?.content.join(" ")}</h5>
      </td>
      <td>
        
        <p>{question?.options[answer.optionNumber].text}</p>
        <p>{answer.extensionText}</p>
      </td>

      <td>{question?.options[answer.optionNumber].feedbackContent}</td>
    </tr>
  );
}

export default function CompleteAnswersTable(props: {
  enrollment: IEnrollment;
}) {
  const { enrollment } = props;
  return (
    <table className="table">
      <thead>
        <tr>
          <th style={{ width: "33%" }}>Pergunta</th>
          <th style={{ width: "33%" }}>Resposta</th>
          <th>Conclusão</th>
        </tr>
      </thead>
      <tbody>{enrollment.answers.map(renderAnswer)}</tbody>
    </table>
  );
}
