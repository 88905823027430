export default function MediaProdutividadeMediaInovacao(){
    return (
      <div>
        <h1 className="display-1">Média Produtividade, Média Inovação</h1>
        <p className="lead">
        Baseado nas decisões tomadas durante o jogo, sua empresa tem perdido 
        alguns negócios por dificuldade em atender todas as demandas atuais 
        do mercado em termos de produtividade. <br />
        Outro ponto, o baixo investimento 
        na inovação de seus processos, indicada pelas suas ações durante jogo, 
        levanta a possibilidade de que seus custos atuais para manter essa alta 
        produtividade podem estar um pouco acima do que deveriam. <br />
        Além disso, a entrada em novos mercados pode estar vinculada a 
        capacidade de inovação e a capacidade de produzir conforme as demandas dos seus clientes.
        </p>
      </div>
    );
}