import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q3",
  context: [
    "Nosso melhor projetista está mudando para o Nordeste e sei que não podemos perdê-lo pois ele é vital para o dia a dia da nossa empresa.",
    "Como você sabe, nossa política de trabalho remoto já é aplicada para algumas áreas, porém, nunca tivemos pesssoas do time de engenharia nessa situação.",
  ],
  content: [
    "Como podemos fazer para mantê-lo no time mesmo que de maneira remota?",
  ],
  options: [
    {
      text: "Infelizmente será inviável uma vez que nossos arquivos de projetos ficam armazenados numa infraestrutura local que não permite acesso remoto.",
      xValue: 0,
      yValue: 0,
      feedbackContent:
        "Sobre trabalho remoto, a técnologia ao longo dos anos vem trazendo novas oportunidades e formas diferentes de pensar o assunto. Perder um colaborador tão importante que tem bons resultados pode afetar a produtividade da empresa.",
    },
    {
      text: "Podemos colocá-lo em um projeto que não tenha colaboração com outros profissionais da equipe e usaremos uma ferramenta de compartilhamento de arquivos, por exemplo, WeTransfer, Google Drive, entre outras.",
      xValue: 0.25,
      yValue: 0.5,
      feedbackContent:
        "A colaboração entre as equipes e gerência é um ponto chave para atingir os objetivos e ter um fluxo de trabalho produtivo. E ter ferramentas de comunicação e compartilhamento de arquivos desvinculadas com o seu PLM como WeTransfer, Google Drive podem acarretar conflitos futuros.",
    },
    {
      text: "A única maneira de fazer isso atualmente é trabalhando via VPN para acessar o servidor.",
      xValue: 0.5,
      yValue: 0.25,
      feedbackContent:
        "Trabalhar via VPN, acessando o servidor remotamente, pode se tornar uma boa ideia inicialmente, mas a longo prazo as grandes interrupções e lentidão de conexão pode acabar deixando o fluxo de trabalho nada fluído.",
    },
    {
      text: "Utilizaremos uma plataforma de colaboração na nuvem e isso resolveria o desafio de ter nosso projetista de maneira remota.",
      xValue: 0.75,
      yValue: 0.75,
      extension: {
        type: "OpenAnswer",
        title: "Qual plataforma vc utiliza?",
      },
      feedbackContent:
        "Ao disponibilizar uma plataforma de colaboração em nuvem para o seu projetista, habilita-o estar em qualquer lugar do mundo, usar os documentos e desenhos atualizados e em tempo real, fazendo com que ele continue entregando um ótimo trabalho tanto quanto presencial.",
    },
  ],
  backgroundImage: "/img/cafeteria/bg-elevator.jpg",
  characterName: "Leonor - Gerente de RH",
  sceneNumber: 8,
} as IQuestion;
