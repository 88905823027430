import { observer } from "mobx-react-lite";
import { InputBox, MultipleChoiceBox, SingleChoiceBox } from "../../components/Inputs";
import Modal from "../../components/Modal";
import { answerQuestion, questionState } from "../../services/Question";
import { IInputOption } from "../../types/InputOption";
import {
  IQuestionOptionExtension,
  IQuestionOptionExtensionItem
} from "../../types/QuestionInterface";

/**
 * Changes from input option to question option extension item
 * @param options 
 * @returns 
 */
const extensionFromInputOptions = (
  options: IInputOption[]
): IQuestionOptionExtensionItem[] | undefined =>
  options
    .filter((o) => o.selected === true)
    .map((o) =>
      questionState.extensionVisible?.items?.find((i) => i.text === o.label)
    ) as IQuestionOptionExtensionItem[] | undefined;

/**
 * Changes from extension items to input options.
 * @param extension
 * @returns
 */
const inputOptionsFromExtension = (
  extension: IQuestionOptionExtension
): IInputOption[] =>
  extension.items?.map(
    (i) =>
      ({
        label: i.text,
        selected: questionState.answerExtensionItems?.find(
          (j) => j.text === i.text
        )
          ? true
          : false,
        value: i,
      } as IInputOption)
  ) || [];

function ExtensionOpenText(extension: IQuestionOptionExtension) {
  return (
    <InputBox
      label={extension.title}
      value={questionState.answerText}
      onChange={(t) => questionState.setAnswerText(t)}
      state={questionState.extensionState}
    />
  );
}

function ExtensionSingleChoice(extension: IQuestionOptionExtension) {
  const onChange = (options: IInputOption[]) => {
    const extItems = extensionFromInputOptions(options);
    questionState.setAnswerExtensionItems(extItems);
  };
  const extOptions = inputOptionsFromExtension(extension);
  return (
    <SingleChoiceBox
      label={extension.title}
      state={questionState.extensionState}
      name={questionState.answerOption?.text ?? ""}
      message="Selecione a opção desejada"
      onChange={onChange}
      options={extOptions}
    />
  );
}

function ExtensionMultipleChoice(extension: IQuestionOptionExtension) {
  const onChange = (options: IInputOption[]) => {
    const extItems = extensionFromInputOptions(options);
    questionState.setAnswerExtensionItems(extItems);
  };
  const extOptions = inputOptionsFromExtension(extension);
  return (
    <MultipleChoiceBox
      label={extension.title}
      state={questionState.extensionState}
      message="Selecione pelo menos uma opção"
      onChange={onChange}
      options={extOptions}
    />
  );
}

function UnknownExtension(extension: IQuestionOptionExtension) {
  console.error("Unkown extension", extension);
  return null;
}

function ExtensionContent(extension: IQuestionOptionExtension) {
  switch (extension.type) {
    case "OpenAnswer":
      return ExtensionOpenText(extension);
    case "MultipleChoice":
      return ExtensionMultipleChoice(extension);
    case "SingleChoice":
      return ExtensionSingleChoice(extension);
    default:
      return UnknownExtension(extension);
  }
}

export default observer(function QuestionOptionExtension() {
  const extension = questionState.extensionVisible;
  
  return (
    <Modal
      visible={extension ? true : false}
      footer={
        <button className="btn btn-primary" onClick={answerQuestion}>
          Responder
        </button>
      }
    >
      {extension ? ExtensionContent(extension) : null}
    </Modal>
  );
});
