import { questionState } from "../../services/Question";
import TextPopper from "./TextPopper";

export default function QuestionAnswer() {
  const { scene, characterName = "", answerContent = "" } = questionState;
  const characterIndex = scene.optionsNames.indexOf(characterName);
  const position = scene.answerPos[characterIndex];
  if (!position) {
    console.error("Unable to locate position for ", characterName);
    return null;
  }
  return (
    <TextPopper
      position={position}
      content={`${answerContent}`}
      title={characterName}
      visible={true}
    />
  );
}
