import { ITrack } from "../../types/TrackInterface";

export default function TrackCard(props: {
  track: ITrack;
  onSelect: (track: ITrack) => void;
}) {
  const { track, onSelect } = props;
  const clickTrack = () => onSelect(track);
  return (
    <div className="card">
      <div className="selectable-character" onClick={clickTrack}>
        <img
          src={track.image}
          className="card-img-top"
          height="400px"
          alt={track.title}
        />
        <div className="card-body">
          <h5 className="card-title">{track.title}</h5>
          <p className="card-text">{track.description}</p>
        </div>
      </div>
    </div>
  );
}
