import {
  GoogleAuthProvider,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  User,
  UserCredential,
} from "firebase/auth";
import { makeAutoObservable } from "mobx";
import { auth } from "../Firebase";
import { extractNameFromEmail } from "../utils/Strings";

class AuthenticationState {
  user?: User | null;
  loading = true;
  error?: string;
  success?: string;

  authLoading: boolean = false;
  authError?: string;
  authSuccess?: string;

  get userId() {
    return this.user?.uid;
  }
  get displayName() {
    return this.user?.displayName || extractNameFromEmail(this.user?.email);
  }
  get email() {
    return this.user?.email;
  }
  get emailVerified() {
    return this.user?.emailVerified;
  }
  get isLoggedIn() {
    return !!this.user;
  }

  async setUser(user?: User | null) {
    this.user = user;
    this.error = undefined;
    this.success = undefined;
    this.authError = undefined;
    this.authSuccess = undefined;
    this.loading = false;
    this.authLoading = false;
  }
  setError(error?: string) {
    this.error = error;
    this.success = undefined;
    this.loading = false;
  }
  setSuccess(success?: string) {
    this.success = success;
    this.error = undefined;
    this.loading = false;
  }
  startLoading() {
    this.error = undefined;
    this.success = undefined;
    this.loading = true;
  }
  setAuthStart() {
    this.authError = undefined;
    this.authSuccess = undefined;
    this.authLoading = true;
  }
  setAuthError(error?: string) {
    this.authError = error;
    this.authLoading = false;
  }
  setAuthSuccess(success?: string) {
    this.authSuccess = success;
    this.authLoading = false;
  }
  get loggedInAndVerified() {
    return this.user && this.user.emailVerified;
  }
  get isAdmin() {
    const email = this.email;
    if (!email) return false;
    return (
      email.endsWith("@3ds.com") ||
      [
        "rodrigo.portela@gmail.com",
        "pythoncompsim@gmail.com",
        "abms.silva@gmail.com",
      ].indexOf(email) >= 0
    );
  }
  constructor() {
    makeAutoObservable(this);
  }
}

export const authenticationState = new AuthenticationState();

onAuthStateChanged(auth, (user: User | null | undefined) => {
  authenticationState.setUser(user);
});

/**
 *
 */
export const signOut = () => {
  auth.signOut().then(() => (window.location.href = "/"));
};

/**
 *
 */
export const signInEmailPassword = async (email: string, password: string) => {
  authenticationState.setAuthStart();
  try {
    const credential: UserCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    if (!credential.user) {
      authenticationState.setAuthError("Usuário ou senha inválidos");
    } else {
      authenticationState.setUser(credential.user);
    }
  } catch (err: any) {
    authenticationState.setAuthError(String(err));
  }
};

/**
 *
 */
export const resendVerificationEmail = () => {
  if (authenticationState.user) {
    authenticationState.setAuthStart();
    sendEmailVerification(authenticationState.user)
      .then(() => {
        authenticationState.setAuthSuccess(
          "Um e-mal foi enviado para você. Clica lá no link."
        );
      })
      .catch((err) => {
        authenticationState.setAuthError(String(err));
      });
  } else {
    authenticationState.setAuthError(
      "Não encontramos um usuário para enviar o e-mail."
    );
  }
};

/**
 *
 */
export const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  authenticationState.setAuthStart();
  signInWithPopup(auth, provider)
    .then((credential) => {
      authenticationState.setUser(credential.user);
    })
    .catch((err) => {
      authenticationState.setAuthError(String(err));
    });
};
