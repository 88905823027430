import { observer } from "mobx-react-lite";
import { NavLink } from "react-router-dom";
import {
  authenticationState, signOut
} from "../../services/Authentication";

export default observer(function SideNav() {
  return (
    <ul className="list-group">
      <li className="list-group-item">
        Nome para Exibição
        <br /> <b>{authenticationState.displayName}</b>
      </li>
      <li className="list-group-item">
        E-mail
        <br /> <b>{authenticationState.email}</b>
      </li>
      <li className="list-group-item">
        E-mail verificado
        <br />
        <b>{authenticationState.emailVerified === true ? "Sim" : "Não"}</b>
      </li>
      <li className="list-group-item">
        Último login
        <br />
        <b>{authenticationState.user?.metadata.lastSignInTime}</b>
      </li>
      <li className="list-group-item">
        Data de criação
        <br />
        <b>{authenticationState.user?.metadata.creationTime}</b>
      </li>
      <li className="list-group-item">
        <button className="btn btn-secondary" onClick={signOut}>
          Sair
        </button>
      </li>
      {authenticationState.isAdmin ? (
        <li className="list-group-item">
          <NavLink to="/reports">Relatórios</NavLink>
        </li>
      ) : null}
    </ul>
  );
});
