import * as ECharts from "echarts";
import { EChartsOption } from "echarts";
import { useEffect, useRef } from "react";

export default function Echart(props: { options: EChartsOption, height: string | number }) {
    const { options, height } = props;
    const ref = useRef(null);
    useEffect(() => {
        if (!ref.current) return;
        const myChart = ECharts.init(ref.current);
        myChart.setOption(options);
    }, [options]);
    return (
        <div ref={ref} style={{ height }} />
    );
}