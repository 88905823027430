import AppLayout from "../../components/AppLayout";

export default function QuestionNotFound(){
  return (
    <AppLayout>
      <div style={{ padding: "50px", textAlign: "center" }}>
        Não encontramos esta pergunta.
      </div>
    </AppLayout>
  );
}