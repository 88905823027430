import { ReactElement, ReactNode } from "react";

export default function ActionSuspense(props: {
  loading: boolean;
  error?: string;
  children?: ReactNode;
  success?: string;
  errorComponent?: ReactElement;
}) {
  const { loading, error, children, success, errorComponent } = props;

  if (loading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  } else if (errorComponent) {
    return errorComponent;
  } else if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        {error}
      </div>
    );
  } else if (success) {
    return (
      <>
        <div className="alert alert-success" role="alert">
          {success}
        </div>
        {children ?? null}
      </>
    );
  } else {
    return <>{children || null}</>;
  }
}