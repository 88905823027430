import q1 from "./questions/3ds_q01";
import q2 from "./questions/3ds_q02";
import q3 from "./questions/3ds_q03";
import q4 from "./questions/3ds_q04";
import q5 from "./questions/3ds_q05";
import q6 from "./questions/3ds_q06";
import q7 from "./questions/3ds_q07";
import q8 from "./questions/3ds_q08";
import q9 from "./questions/3ds_q09";
import q10 from "./questions/3ds_q10";
import q12 from "./questions/3ds_q12";
import q8e from "./questions/3ds_q08-e";
import q9e from "./questions/3ds_q09-e";
export const allQuestions = [
  q1,
  q2,
  q3,
  q4,
  q5,
  q6,
  q7,
  q8,
  q9,
  q10,
  q12,
  q8e,
  q9e,
];