import { NavLink } from "react-router-dom";
import AppLayout from "../../components/AppLayout";
import Preload from "./Preload";

export default function Home() {
  return (
    <>
      <AppLayout backgroundImg="/img/scene11_factory_manu01c.png">
        <div className="container">
          <div className="col-sm-8">
            <div
              style={{
                marginTop: "20px",
                padding: "20px",
                backgroundColor: "rgba(255, 255, 255, 0.66)",
                width: "100%",
                textAlign: "left",
              }}
            >
              <h1>Simulador 3DEXPERIENCE</h1>
              <div className="" style={{ fontWeight: "500" }}>
                <p>
                  Olá, queremos convidá-lo a participar do 3DEXPERIENCE Game!
                </p>
                <p>
                  O mundo todo foi afetado pelos eventos dos últimos dois anos,
                  por isso hoje, mais do que nunca as empresas precisam se
                  adaptar ao novo normal e as novas demandas de mercado com mais
                  rapidez e eficiência.
                </p>
                <p>
                  Participe do 3DEXPERIENCE game, uma atividade dinâmica, rápida
                  e interativa e por meio dela tenha alguns insights sobre o
                  perfil de sua empresa. Ao final receba um diagnóstico de
                  como sua empresa está atualmente em termos de produtividade e
                  inovação frente a empresas do mesmo setor, além de
                  recomendações dos consultores da Dassault Systèmes.
                </p>
                <p>Preencha o formulário e aproveite o 3DEXPERIENCE Game!</p>
              </div>
              <div style={{ padding: "5px" }}>
                <NavLink className="btn btn-primary" to={"/simulation"}>
                  Iniciar
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
      <Preload />
    </>
  );
}
