import { makeAutoObservable } from "mobx";
import enrollmentDatabase from "../database/EnrollmentDatabase";
import { allQuestions } from "../database/QuestionDatabase";
import { IScene, SCENES } from "../database/SceneDatabase";
import { IEnrollmentAnswer } from "../types/EnrollmentInterface";
import { InputState } from "../types/InputState";
import {
  IQuestion,
  IQuestionOption,
  IQuestionOptionExtension,
  IQuestionOptionExtensionItem,
} from "../types/QuestionInterface";
import enrollmentState, {
  completeEnrollment,
  findQuestionAnswer,
} from "./Enrollment";

class QuestionState {
  question?: IQuestion;
  optionsVisible?: boolean;
  extensionVisible?: IQuestionOptionExtension;
  answerText?: string;
  answerOption?: IQuestionOption;
  answerExtensionItems?: IQuestionOptionExtensionItem[];
  characterName?: string;
  windowWidth = window.innerWidth;
  windowHeight = window.innerHeight;

  setOptionsVisible(value?: boolean) {
    this.optionsVisible = value;
    this.extensionVisible = undefined;
  }

  setExtensionVisible(value?: IQuestionOptionExtension) {
    this.extensionVisible = value ? { ...value } : undefined;
  }

  setAnswerText(text?: string) {
    this.answerText = text;
  }

  setQuestion(question?: IQuestion) {
    this.question = question;
    const answer = findQuestionAnswer(question?.questionId);
    if (answer) {
      this.characterName = answer.characterName;
      this.answerOption = question?.options[answer.optionNumber];
      this.answerText = answer.extensionText;
      this.answerExtensionItems = answer.extensionItems.map((t) =>
        this.answerOption?.extension?.items?.find((i) => i.text === t)
      ) as IQuestionOptionExtensionItem[];
    } else {
      this.answerOption = undefined;
      this.answerText = undefined;
      this.optionsVisible = false;
      this.answerExtensionItems = undefined;
    }
  }

  setOption(option: IQuestionOption, characterName: string) {
    this.answerOption = option;
    this.answerExtensionItems = undefined;
    this.answerText = undefined;
    this.characterName = characterName;
    this.extensionVisible = undefined;
  }
  releaseOption() {
    this.answerOption = undefined;
    this.answerExtensionItems = undefined;
    this.answerText = undefined;
    this.characterName = undefined;
    this.extensionVisible = undefined;
  }

  setAnswerExtensionItems(items?: IQuestionOptionExtensionItem[]) {
    this.answerExtensionItems = items;
  }

  resetDimensions() {
    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;
  }

  get questionCharacter(): string | undefined {
    return this.question?.characterName;
  }

  get extensionState(): InputState {
    if (!this.answerOption) return "invalid";
    else if (!this.extensionVisible) return "normal";
    else if (this.extensionVisible.type === "OpenAnswer")
      return this.answerText ? "valid" : "invalid";
    else
      return (this.answerExtensionItems?.length ?? 0) > 0 ? "valid" : "invalid";
  }

  get scene(): IScene {
    const sIndex = this.question?.sceneNumber ?? 0;
    return SCENES[sIndex];
  }

  get xValue() {
    return (
      (this.answerOption?.xValue ?? 0) +
      (this.answerExtensionItems?.reduce(
        (sum, i) => sum + (i.xValue ?? 0),
        0.0
      ) ?? 0)
    );
  }

  get yValue() {
    return (
      (this.answerOption?.yValue ?? 0) +
      (this.answerExtensionItems?.reduce(
        (sum, i) => sum + (i.yValue ?? 0),
        0.0
      ) ?? 0)
    );
  }

  get questionContent(): string | undefined {
    const question = this.question;
    if (!question) return undefined;
    let content = Array.isArray(question.content)
      ? question.content.join("<br />")
      : question.content;
    let context = question.context?.join("<br />");
    return context ? context + "<br />" + content : content;
  }

  get answerContent(): string | undefined {
    if (!this.question) return undefined;
    if (this.answerOption === undefined) return undefined;
    let content = this.answerOption.text ?? "";
    if (this.answerText) content += " => " + this.answerText;
    return content;
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export const questionState = new QuestionState();
export const showOptions = () => questionState.setOptionsVisible(true);
export const hideOptions = () => questionState.setOptionsVisible(false);
export const currentQuestionIndex = () => {
  const { track } = enrollmentState;
  const questionId = questionState.question?.questionId ?? "";
  return track?.questions.indexOf(questionId) ?? -1;
};
export const hasPreviousButton = () => currentQuestionIndex() > 0;

export const hasNextButton = () =>
  questionState.extensionState === "normal" ||
  questionState.extensionState === "valid";

export const previousQuestion = () => {
  const questionIndex = currentQuestionIndex();
  if (questionIndex > 0) {
    const previousId = enrollmentState.track!.questions[questionIndex - 1];
    const previousQ = allQuestions.find((q) => q.questionId === previousId);
    questionState.setQuestion(previousQ);
  }
};

export const lastQuestion = () => {
  const length = enrollmentState.track!.questions.length;
  const lastId = enrollmentState.track!.questions[length - 1];
  const lastQ = allQuestions.find((q) => q.questionId === lastId);
  enrollmentState.enrollment!.status = "Respondendo";
  questionState.setQuestion(lastQ);
};

export const nextQuestion = () => {
  const questionIndex = currentQuestionIndex();
  const questionLength = enrollmentState.track!.questions.length;
  if (questionIndex < 0) return;
  if (questionIndex < questionLength - 1) {
    const nextId = enrollmentState.track!.questions[questionIndex + 1];
    const nextQ = allQuestions.find((q) => q.questionId === nextId);
    questionState.setQuestion(nextQ);
  } else {
    completeEnrollment();
  }
};

export function setQuestion(questionId?: string) {
  if (questionId) {
    const q = allQuestions.find(
      (question) => question.questionId === questionId
    );
    questionState.setQuestion(q);
  }
}

export const answerQuestion = async () => {
  if (
    questionState.extensionState === "valid" ||
    questionState.extensionState === "normal"
  ) {
    const {
      characterName,
      answerExtensionItems,
      answerText,
      question,
      answerOption,
    } = questionState;
    enrollmentState.setLoading(true);
    const newAnswer: IEnrollmentAnswer = {
      characterName: characterName ?? "",
      extensionItems: answerExtensionItems?.map((i) => i.text) ?? [],
      createdAt: new Date().toISOString(),
      extensionText: answerText ?? "",
      optionNumber:
        question?.options.findIndex((o) => o.text === answerOption?.text) ?? -1,
      questionId: question?.questionId ?? "",
      xValue: questionState.xValue,
      yValue: questionState.yValue,
    };
    const { enrollment } = enrollmentState;
    const oldIndex =
      enrollment?.answers.findIndex(
        (old) => old.questionId === question?.questionId
      ) ?? -1;
    if (oldIndex >= 0) {
      //update has no progress
      enrollment!.answers[oldIndex] = newAnswer;
    } else {
      //insert adds progress
      enrollment!.answers.push(newAnswer);
      enrollment!.progress = enrollmentState.currentProgress;
    }
    enrollmentDatabase.updateEnrollment(enrollment!);
    enrollmentState.setLoading(false);
    questionState.setOptionsVisible(false);
  }
};

const resizeDetector = () => {
  if (
    questionState.windowWidth !== window.innerWidth ||
    questionState.windowHeight !== window.innerHeight
  ) {
    questionState.resetDimensions();
  }
};
setInterval(resizeDetector, 1000);
