import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Spinner from './components/Spinner';
import About from './pages/about';
import Home from './pages/home';
import Profile from './pages/profile';
import Reports from './pages/reports';
import Simulation from './pages/simulation';

function App() {
  return (
    <Suspense fallback={<Spinner />}>
      <BrowserRouter>
        <Routes>
          <Route path="/profile/*" element={<Profile />} />
          <Route path="/simulation/*" element={<Simulation />} />
          <Route path="/reports/*" element={<Reports />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
