import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
  UserCredential,
} from "firebase/auth";
import React, { useState } from "react";
import { Checkbox, TextField } from "../../components/FormFields";
import profileDatabase, { IProfile } from "../../database/ProfileDatabase";
import { auth } from "../../Firebase";

export default function Register() {
  const [state, setState] = useState({
    sending: false,
    error: "",
    success: "",
    target: { marketingAuthorized: "Não" } as Record<string, string>,
  });
  const { sending, error, success, target } = state;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setState({ sending: true, error: "", success: "", target: target });

    const { name, email, password, passwordConfirmation } = target;
    const {
      phone,
      company,
      position,
      zipCode,
      city,
      country,
      dataProcessAuthorized,
      marketingAuthorized,
    } = target;

    if (!email || !password) return;
    if (password !== passwordConfirmation) {
      setState({
        sending: false,
        error: "A confirmação não confere com a senha.",
        success: "",
        target: target,
      });
      return;
    }

    console.log("setei estado");
    createUserWithEmailAndPassword(auth, email, password)
      .then((credential: UserCredential) => {
        if (!credential.user) {
          setState({
            sending: false,
            error: "Usuário / senha inválidos",
            success: "",
            target: target,
          });
        } else {
          updateProfile(credential.user, { displayName: name });
          const profile: IProfile = {
            id: credential.user.uid,
            name,
            email,
            phone,
            company,
            position,
            zipCode,
            city,
            country,
            role: "user",
            dataProcessAuthorized,
            marketingAuthorized,
          };
          profileDatabase.setProfile(profile);
          sendEmailVerification(credential.user)
            .then(() => {
              setState({
                sending: false,
                error: "",
                success:
                  "Um link de confirmação de e-mail foi enviado para você.",
                target: target,
              });
            })
            .catch((err) => {
              setState({
                sending: false,
                error: err.message || err.toString(),
                success: "",
                target: target,
              });
            });
        }
      })
      .catch((error2) => {
        setState({
          sending: false,
          error: error2.message || error2,
          success: "",
          target: target,
        });
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit} style={{ paddingTop: "20px" }}>
        <TextField
          name="name"
          placeholder="Nome Sobrenome"
          required
          target={target}
          title="Nome*:"
          disabled={sending}
        />
        <TextField
          name="email"
          placeholder="nome@dominio.com"
          required
          target={target}
          title="Endereço de e-mail*:"
          type="email"
          disabled={sending}
        />
        <TextField
          name="phone"
          placeholder="(xx) 12345-1234"
          required
          target={target}
          title="Telefone (ddd) + numero*:"
          type="text"
          disabled={sending}
          minLength={10}
          maxLength={30}
        />
        <TextField
          name="company"
          placeholder="Nome da Empresa"
          required
          target={target}
          title="Nome da Empresa*:"
          type="text"
          disabled={sending}
          minLength={2}
          maxLength={30}
        />
        <TextField
          name="position"
          placeholder="Ex: Gerente de Planta"
          required
          target={target}
          title="Cargo*:"
          type="text"
          disabled={sending}
          minLength={3}
          maxLength={30}
        />
        <TextField
          name="zipCode"
          placeholder="Ex: 12345678"
          required
          target={target}
          title="CEP (somente números)*:"
          type="text"
          disabled={sending}
          minLength={8}
          maxLength={8}
        />
        <TextField
          name="city"
          placeholder="Ex: São Paulo"
          required
          target={target}
          title="Cidade*:"
          type="text"
          disabled={sending}
          minLength={5}
          maxLength={18}
        />
        <TextField
          name="country"
          placeholder="Ex: Brasil"
          required
          target={target}
          title="País*:"
          type="text"
          disabled={sending}
          minLength={5}
          maxLength={30}
        />
        <TextField
          name="password"
          placeholder="[senha]"
          required
          target={target}
          title="Senha*:"
          type="password"
          disabled={sending}
          minLength={6}
          maxLength={30}
        />
        <TextField
          name="passwordConfirmation"
          placeholder="[mesma senha]"
          required
          target={target}
          title="Confirme a Senha*:"
          type="password"
          disabled={sending}
          minLength={6}
          maxLength={30}
        />
        <Checkbox
          name="dataProcessAuthorized"
          title={
            <span>
              Reconheço e aceito que meus dados pessoais sejam processados de acordo com a {" "}
              <a
                href="https://www.3ds.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                política de privacidade
              </a>{" "}
              da 3DS e estou ciente de que, para fins comerciais, a 3DS poderá compartilhá-los com 
              partes interessadas confiáveis (incluindo parceiros de negócios e patrocinadores de
              eventos da {" "}
              <a
                href="https://www.3ds.com/partners/locate-partner"
                target="_blank"
                rel="noreferrer"
              >
                Dassault Systèmes
              </a>{" "}          
              e {" "}
              <a
                href="https://www.solidworks.com/pt-br/how-to-buy/find-solidworks-reseller/"
                target="_blank"
                rel="noreferrer"
              >
                SolidWorks
              </a>{" "}          
              , conforme detalhado nos formulários de registro).
            </span>
          }
          required
          disabled={sending}
          target={target}
        />
        <br />
        <Checkbox
          name="marketingAuthorized"
          title={
            <span>
              Aceito receber comunicações de marketing sobre Produtos ou
              Serviços da 3DS.
            </span>
          }
          disabled={sending}
          target={target}
        />

        {sending ? (
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              style={{ width: "100%" }}
            />
          </div>
        ) : null}
        {error ? (
          <div className="alert alert-danger" role="alert">
            <h4 className="alert-heading">Ooops!</h4>
            <p>{error}</p>
          </div>
        ) : null}
        {success ? (
          <div className="alert alert-success" role="alert">
            <h4 className="alert-heading">Muito bem!</h4>
            <p>{success}</p>
          </div>
        ) : null}
        <div style={{ padding: "20px 0px" }}>
          <button type="submit" className="btn btn-primary" disabled={sending}>
            Enviar
          </button>
        </div>
      </form>
    </>
  );
}
